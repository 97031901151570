import * as VueRouter from "vue-router";

export default VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: "",
      name: "Export",
      component: () => import("@/components/Export.vue"),
    },
    {
      path: "/404",
      name: "PageNotFound",
      component: () => import("@/components/PageNotFound.vue"),
    },
    { path: "/", redirect: { name: "PageNotFound" } },
  ],
});
